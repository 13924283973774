#block-menu-menu-secondary,
#block-menu-block-3 {

  .menu {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .menu__item {
    border-bottom: 0;
  }

  .menu__link {
    color: $color-black;
    display: block;
    font-family: $font-headline;
    font-size: 1.5em;
    transform: rotate(-7deg);
  }
}
