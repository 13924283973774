$component: 'subsection__teaser';


.#{$component} {

  &-inner {
    background: $color-grey;
    //background: linear-gradient(to top, rgba($color-black,0.75), rgba($color-black,0));
    bottom: 0;
    display: flex;
    margin: 0 auto;
    max-width: $page-max-width;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      background: linear-gradient(to top, rgba($color-black,0.75), rgba($color-black,0));
      left: 0;
      padding: 0;
      position: absolute;
    }

    @include breakpoint($breakpoint-wide) {
      background: none;
      bottom: auto;
      height: 100%;
      left: auto;
      padding: 0 $page-gutter;
      position: relative;
    }

    .paragraphs-item-subsection.right & {
      justify-content: flex-end;
    }
  }

  &-headline {
    position: relative;

    @include breakpoint($breakpoint-narrow) {
      margin: 0;
      position: static;
    }
  }



  &-headline-inner {
    background: linear-gradient(to top, rgba($color-black,0.75), rgba($color-black,0));
    bottom: 0;
    padding: 1rem $page-gutter / 2;
    position: absolute;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      background: none;
      bottom: auto;
      margin: 0;
      padding: 0;
      position: static;
      width: auto;
    }
  }

  .field-name-field-teaser {
    background-color: $color-grey-dark;
    margin-top: 0;
    padding: 1.5rem 1rem;

    .highlight & {
      background-color: $color-blue-dark;
    }

    @include breakpoint($breakpoint-narrow) {
      margin-top: 0;
      background-color: transparent;
      padding: 0;

      .highlight & {
        background-color: transparent;
      }
    }
  }

  &-content {
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      margin: 0 auto;
      padding: 3rem $page-gutter;
      width: 75%;
    }

    @include breakpoint($breakpoint-wide) {
      bottom: 8%;
      position: absolute;
      margin: 0;
      padding: 0;
      width: 50%;

      .paragraphs-item-subsection.right & {
        right: 0;
      }
    }

    h1 {
      margin: 0;
    }

    h3 {
      border-radius: 0.15em;
      display: inline-block;
      background-color: $color-grey;
      font-size: 0.75em;
      font-weight: bold;
      letter-spacing: 0.1em;
      margin: 0 0 1.25em;
      padding: 0.4em 0.8em;
    }

    p {
      color: $color-white;
      font-size: px2em(20);
      font-weight: bold;
      line-height: 1.4;
    }
  }
}
