$base-font-size: 1.6em;
$base-line-height: 1.6;

$color-black: #000;
$color-white: rgb(238, 235, 229);;
$color-grey-dark: #252525;
$color-grey: rgba(#292929, 0.95);
$color-grey-light: #979797;
$color-grey-lighter: #C2C2C2;
$color-blue: rgba(#0067A5, 0.9);
$color-blue-dark: #0067A5;

$color-status: #006400;
$color-error: #9d1309;
$color-warning: #b8860b;

$color-primary: #A4C600;
$color-secondary: tint($color-primary, 10%);

$font-color: $color-black;
$font-family: "PT Sans", Helvetica, Arial, sans-serif;
$font-icon: 'FontAwesome';
$font-courier: 'PT Mono';
$font-headline: 'Blog Script';

$page-max-width: 1200px;
$content-max-width: $page-max-width;
$header-height: 4em;

$breakpoint-narrow: 768px;
$breakpoint-tablet-landscape: (min-width $breakpoint-narrow) (orientation landscape);
$breakpoint-wide: 1025px;

$page-gutter-narrow: 2em;
$page-gutter: 2em;
$gutter: 1 / 2;
