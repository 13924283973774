.header {
  padding: 0 $page-gutter;

  @include breakpoint($breakpoint-narrow) {
    padding: 0 $page-gutter-narrow;
  }

  .header-inner {
    max-width: $page-max-width;
    margin: 0 auto;
  }
}

html body.admin-menu {
  margin-top: 0 !important;
}

.page {
  min-height: 100%;
}


.main {

}


.footer {

}
