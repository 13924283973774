$component: 'overlay';

.#{$component} {
  background: $color-primary;
  color: $color-white;
  display: none;
  height: 100%;
  padding: $header-height $page-gutter / 2 $page-gutter / 2 $page-gutter / 2;
  position: fixed;
  width: 100%;
  z-index: 100;
  overflow: hidden;

  @include breakpoint($breakpoint-narrow) {
    padding: $page-gutter / 2;
  }

  &__inner {
    height: 100%;
    overflow: scroll;
  }

  &__checkbox {
    display: none;
  }

  &__menu {
    height: 100%;

    @include breakpoint($breakpoint-wide) {
      display: none;
    }
  }

  &__label {
    background-image: url('../images/icon--menu.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    height: 100%;
    margin: 0;
    width: 1.5em;

    @include breakpoint($breakpoint-narrow) {
      width: 2em;
    }
  }

  &__checkbox:checked ~ .#{$component} {
    display: block;
  }

  &__checkbox:checked ~ .page .#{$component}__label {
    background-image: url('../images/icon--close.svg');
  }

  .region-overlay {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .block {
    margin-bottom: 1em;
    width: 100%;

    &:first-child {
      border-top: 1px solid $color-white;
    }

    &__title {
      display: none;
    }
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu__item {
    border-bottom: 1px solid $color-white;
  }

  .menu__link {
    color: $color-white;
    display: block;
    font-weight: bold;
    padding: 0.5em 0;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: $color-black;
    }
  }
}
