.footer {
  color: $color-white;
  background: linear-gradient(to top, $color-grey, $color-grey), linear-gradient(to top, $color-white, $color-white);
  padding: $page-gutter / 2;
  position: relative;
  z-index: 50;

  @include breakpoint($breakpoint-narrow) {
    padding: $page-gutter;
  }

  &-inner {
    margin: 0 auto;
    max-width: $page-max-width;

    @include breakpoint($breakpoint-narrow) {
      width: 75%;
    }

    @include breakpoint($breakpoint-wide) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .block {
    margin: 1.5em 0;

    @include breakpoint($breakpoint-wide) {
      margin: 0;
      padding: 0 1em;
      width: 100 / 3 * 1%;
    }

    p {
      font-size: px2em(16);
    }

    a {
      color: $color-white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:first-child() {
      margin-top: 0;
      padding-left: 0;
    }

    &:last-child() {
      margin-bottom: 0;
      padding-right: 0;
    }
  }
}
