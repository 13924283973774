$component: 'contact';

.#{$component} {

  &__step {

    display: none;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      width: 60%;
    }

    &:nth-child(1) {
      display: block;

      @include breakpoint($breakpoint-narrow) {
        width: 60%;
      }
    }

    h2 {
      text-align: center;

      @include breakpoint($breakpoint-narrow) {
        text-align: left;

        .#{$component}__progress-0 & {
          text-align: center;
        }
      }
    }
  }

  &__confirmation {
    display: none;
    text-align: center;

    @include breakpoint($breakpoint-narrow) {
      width: 60%;
    }

    h2 {
      text-align: center;
    }

  }

  &__error-message {
    color: $color-primary;
    display: none;
    font-size: px2em(20);
    font-weight: bold;
    margin: px2em(30) 0 0;
  }

  &__error .contact__error-message {
    display: block;
  }

  &__progress {
    color: $color-white;
    margin: 0.5em 0;
    width: 100%;
    text-align: center;

    @include breakpoint($breakpoint-narrow) {
      margin: 0;
      width: auto;
      text-align: left;
    }
  }

  &__video {
    background-color: $color-black;
    display: none;
    height: 140%;
    width: 140%;
    margin-top: -10%;
    margin-left: -20%;
    transition: opacity 0.5s linear;

    @include breakpoint($breakpoint-narrow) {
      display: block;
    }
  }

  &__background {
    position: fixed;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right 33% center;

    @include breakpoint($breakpoint-narrow) {
      display: none;
    }

  }

  &__video-pager {
    align-items: center;
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    justify-content: space-between;

    @include breakpoint($breakpoint-narrow) {
      display: flex;
    }
  }

  &__video-pager-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0.1em solid rgba($color-white, 0.3);
    height: 5em;
    width: 5em;
    pointer-events: auto;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 -2.5em;

    @include breakpoint($breakpoint-wide) {
      height: 6em;
      margin: 0 -3em;
      width: 6em;
    }
  }

  &__video-pager-item--active {
    display: none;
  }

  &__start {
    display: none;
  }

  &__quartier {
    display: none;
  }

  &__progress-0 {
    .#{$component}__start {
      display: block;
    }

    .#{$component}__next,
    .#{$component}__progress {
      display: none;
    }
  }

  &__progress-confirmation {
    .#{$component}__quartier {
      display: inline-block;
    }

    .#{$component}__next,
    .#{$component}__progress {
      display: none;
    }
  }

  &__back {
    background-image: url('../images/ico_arrow_back.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.6em;
    &::before {
      content: '';
      display: block;
      height: 1.6em;
    }
  }
}

.webform-client-form-7 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  margin: $header-height 0 1em;
  z-index: 50;
  width: 100%;
  max-width: $page-max-width;

  @include breakpoint($breakpoint-narrow) {
    border: 0.1em solid rgba($color-white, 0.3);
    border-radius: 1em;
    margin: $header-height + 2em auto 2em;
    padding: 1em 3em;
  }

  @include breakpoint($breakpoint-tablet-landscape) {
    padding: 1em 5em;
  }

  @include breakpoint($breakpoint-wide) {
    margin: 2em auto $header-height + 2em;
    padding: 1em 5em;
  }

  label {
    color: $color-white;
    display: none;
    font-size: px2em(20);
    font-weight: normal;
  }

  h2 {
    @extend %h1;
    margin: 0 0 0.125em;
  }

  h3 {
    @extend %h1;
    color: $color-primary;
    margin: 0;
    text-align: center;
    text-transform: none;
  }

  p {
    color: $color-primary;
    font-size: px2em(18);

    @include breakpoint($breakpoint-narrow) {
      font-size: px2em(24);
    }
  }

  > div:not(.contact__video-pager) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }



  .#{$component}__intro {
    p {
      color: $color-white;
      font-size: px2em(18);
      text-align: center;

      @include breakpoint($breakpoint-narrow) {
        font-size: px2em(20);
      }
    }
  }

  .#{$component}__confirmation {
    p {
      font-size: px2em(18);
      text-align: center;

      @include breakpoint($breakpoint-narrow) {
        font-size: px2em(20);
      }
    }
  }

  .fieldset-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .webform-component {
    width: 100%;

    &-textfield {
      margin: 0.5em 0;

      @include breakpoint($breakpoint-narrow) {
        margin: 1.5em 0;
      }
    }

    &-checkboxes label {
      display: block;
    }

    &--step-2--strasse,
    &--step-2--ort {
      display: inline-block;

      @include breakpoint($breakpoint-narrow) {
        width: 100 / 3 * 2%;
      }
    }

    &--step-2--hausnummer,
    &--step-2--plz {
      display: inline-block;

      @include breakpoint($breakpoint-narrow) {
        width: 100 / 3 * 1%;
      }
    }

    &--step-4--rechtliches p {
      color: $color-white;
      font-size: px2em(12);
    }
  }

  .form-checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1em 0;

    @include breakpoint($breakpoint-narrow) {
      justify-content: flex-start;
    }

    label {
      @extend %button;
      background: rgba($color-white, 0.1);
      border: 0.1em solid rgba($color-white, 0.3);
      color: $color-white;
      font-size: 1em;
      margin: 0.25em 0;

      @include breakpoint($breakpoint-narrow) {
        margin: 0;
      }

      &:hover {
        background: $color-white;
        border: 0.1em solid rgba($color-white, 0.3);
        color: $color-primary;
      }
    }

    .form-item {
      margin-right: 0.5em;
    }
  }

  .form-checkbox {
    display: none;

    &:checked + label {
      background: $color-white;
      border: 0.1em solid rgba($color-white, 0.3);
      color: $color-primary;
    }
  }

  .form-text,
  .form-textarea {
    background-color: transparent;
    border: 0;
    border-bottom: 0.1em solid rgba($color-white, 0.3);
    box-shadow: none;
    color: $color-white;
    font-size: px2em(18);
    margin: 0;
    padding: 0.25em 0;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      font-size: px2em(20);
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: $color-white;
    }

    &::-moz-placeholder {
      color: $color-white;
    }

    &:-ms-input-placeholder {
      color: $color-white;
    }

    &:-moz-placeholder {
      color: $color-white;
      font-size: px2em(20);
    }
  }

  .form-textarea {
    border: 1px dotted rgba($color-primary, 0.8);
    border-radius: 0.4em;
    color: $color-primary;
    height: 300px;
    margin: px2em(30) 0 0;
    padding: px2em(20);

    @include breakpoint($breakpoint-tablet-landscape) {
      height: auto;
    }

    @include breakpoint($breakpoint-wide) {
      height: auto;
    }

    &::-webkit-input-placeholder {
      color: $color-primary;
    }

    &:-moz-placeholder {
      color:$color-primary;
    }

    &::-moz-placeholder {
      color:$color-primary;
    }

    &:-ms-input-placeholder {
      color:$color-primary;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent;
    }

    &:focus:-moz-placeholder {
      color:transparent;
    }

    &:focus::-moz-placeholder {
      color:transparent;
    }

    &:focus:-ms-input-placeholder {
      color:transparent;
    }

  }

  .form-actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: px2em(30);
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      justify-content: flex-start;
    }
  }

  &.#{$component}__progress-0 .form-actions,
  &.#{$component}__progress-confirmation .form-actions {
    justify-content: center;

    @include breakpoint($breakpoint-narrow) {
      width: 60%;
    }
  }


  .form-submit {
    display: none;
  }
}

.node-webform {
  display: flex;
  min-height: 100vh;
  overflow: auto;
  padding: 0 1em;

  @include breakpoint($breakpoint-narrow) {
    padding: 0 4em;
  }

  &--hide-video {
    .contact__video {
      opacity: 0;
    }

    .contact__background {
      display: none;
    }
  }

  .subsection__teaser-background {
    background-color: $color-black;
    position: fixed;
    padding: 2em 0 2em;
    height: 100vh;
    left: 0;
    opacity: 1;

    @include breakpoint($breakpoint-narrow) {
      //background-image: none !important;
      padding: 6em 0 2em;
    }

    @include breakpoint($breakpoint-wide) {
      padding: 2em 0 6em;
    }

    &::after {
      background: linear-gradient(to right, rgba($color-black, 0.6), transparent);
      content: '';
      height: 100vh;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .paragraphs-items-field-contents {
    z-index: 1;
  }

  .paragraphs-item-video {
    display: none;
  }


  .contact__start {
    display: none;
  }
}
