#block-menu-menu-share,
#block-menu-block-2 {

  .block__title {
    margin-right: 0.25em;
  }

  .menu {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .menu__item {
    border: 0;
    margin: 0 0.25em;
  }


  .menu__link {
    display: flex;
    height: px2em(20);
    overflow: hidden;
    padding: 0;
    position: relative;
    visibility: hidden;
    width: px2em(20);

    &::before {
      align-items: center;
      display: flex;
      content: '';
      font-family: $font-icon;
      height: 100%;
      visibility: visible;
    }
  }

  .menu__link[href*="mailto"] {
    &::before {
      content: '\f0e0';
    }
  }

  .menu__link[href*="facebook"]::before {
    content: '\f230';
  }
}
