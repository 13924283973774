$component: 'poi';


.#{$component} {
  width: 100%;
  // overflow: auto;
  position: relative;
  z-index: 30;

  @include breakpoint($breakpoint-wide) {
    height: 200vh;
  }

  > .view-content {
    position: relative;
    height: 125vw;
    left: 0;
    width: 100%;
    top: 0;

    @include breakpoint($breakpoint-narrow) {
      height: 100vw;
    }

    @include breakpoint($breakpoint-tablet-landscape) {
      height: 100vh;
    }

    @include breakpoint($breakpoint-wide) {
      position: absolute;
      height: 100vh;
    }
  }

  &.subsection--fixed > .view-content {
    position: fixed;
    top: 0;
  }

  .attachment-after {
    background: $color-grey;
    color: $color-white;
    position: relative;
    z-index: 50;
    padding: $page-gutter / 2;

    @include breakpoint($breakpoint-narrow) {
      padding: $page-gutter / 2 $page-gutter;
    }

    @include breakpoint($breakpoint-wide) {
      background: none;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 100;
    }

    h2 {
      margin: 0;
    }

    .view-content {


      @include breakpoint($breakpoint-narrow) {
        margin: 0 auto;
        width: 75%;
      }

      @include breakpoint($breakpoint-wide) {
        background: $color-grey;
        margin: 15vh 0 0;
        pointer-events: auto;
        width: 40%;
      }
    }

    .views-row {
      width: 100%;
      padding-top: 4em;

      @include breakpoint($breakpoint-narrow) {
        padding-top: 5em;
      }

      @include breakpoint($breakpoint-wide) {
        padding: 5em $page-gutter $page-gutter / 2;
      }
    }

    .node-teaser {
      //background: $color-grey;
      border-top: 1px solid lighten($color-grey,10%);
      padding-top: 1.25em;

      @include breakpoint($breakpoint-wide) {

      }
    }
  }

  .view-display-id-attachment_1 {
    margin: 0 auto;
    max-width: $page-max-width;
  }

  &__pager {
    position: absolute;
    width: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0;
    z-index: 200;
    padding: $page-gutter / 2;

    @include breakpoint($breakpoint-narrow) {
      padding: $page-gutter $page-gutter;
    }


     > div {
       color: $color-white;
       font-size: px2em(20);
       font-weight: bold;
       margin: 0;
     }

     &-next,
     &-prev {
       cursor: pointer;
       height: 0.9em;
       width: 1em;
       background-repeat: no-repeat;
       background-size: contain;
       background-position: center;

       @include breakpoint($breakpoint-narrow) {
         height: 1.5em;
         width: 1em;
       }
     }

     &-next {
       background-image: url('../images/ico_arrow_fwd.svg');
     }

     &-prev {
       background-image: url('../images/ico_arrow_back.svg');
     }
  }

  .field-name-field-image {
    margin-top: 2em;
  }

  .field-name-field-caption {
    margin-bottom: 2em;
  }

  .gmap {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
}
