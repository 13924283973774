html {
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  background-color: $color-grey;
  color: $font-color;
  font-family: $font-family;
  line-height: $base-line-height;
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
%h1 {
  color: $color-white;
  font-family: $font-headline;
  font-size: px2em(32);
  font-weight: normal;
  line-height: (74 / 54);
  letter-spacing: -0.025em;
  margin: 0.25em 0 0.5em;

  @include breakpoint($breakpoint-narrow) {
    font-size: px2em(48);
  }

  @include breakpoint($breakpoint-wide) {
    font-size: px2em(54);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h2,
%h2 {
  color: $color-primary;
  font-family: $font-family;
  font-size: px2em(20);
  font-weight: normal;
  margin: 1em 0 0.5em;
  line-height: (26 / 20);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h3 {
  color: $color-white;
  font-size: px2em(16);
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  margin: 0;
  padding: 1em;
  background-color: $color-primary;

  h1,
  h2,
  h3,
  a,
  p {
    color: $color-white;
  }
}

p,
%p {
  font-size: px2em(18);
  margin: 0.5em 0em;

  @include breakpoint($breakpoint-narrow) {
    font-size: px2em(17);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.highlight {
    color: $color-primary;
    font-size: 300%;
    margin: 0.25 0;
    line-height: 1;
  }

  & + &.highlight {
    margin-top: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

a {
  text-decoration: none;
  color: $color-primary;

  &:hover {
    text-decoration: underline;
  }
}

ul {

  .messages & {
    padding: 0;
  }
}

table {
  border: 0;
  width: 100%;
}

td,
th {
  border: 0;
  border-bottom: 0.1em solid $color-grey-light;
  padding: 0.25em 0.25em 0.25em 0;
  text-align: left;
}

th {
  color: $color-primary;
  font-family: $font-family;
}

.button,
%button {
  border-radius: 0.25em;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-size: px2em(15);
  font-weight: bold;
  background: $color-primary;
  padding: 0.5em 1em;
  letter-spacing: 0.5px;
  margin-right: 0.5em;
  text-transform: uppercase;
  text-align: center;

  a {
    color: $color-white;
    text-decoration: none;
  }
}

.element-invisible {
  display: none;
}
