.field-name-field-caption {
  color: $color-grey-lighter;
  font-family: $font-courier;
  font-size: px2em(12);
}

.field-name-field-file {

  .file-icon {
    display: none;
  }

  a {
    align-items: center;
    display: flex;
  }

  a::before {
    content: '';
    background-image: url('../images/ico_download.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
  }
}
