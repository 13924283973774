body::before {
  content: 'breakpoint-mobile';
  position: absolute;
  opacity: 0;

  @include breakpoint($breakpoint-narrow) {
    content: 'breakpoint-narrow';
  }

  @include breakpoint($breakpoint-tablet-landscape) {
    content: 'breakpoint-tablet';
  }

  @include breakpoint($breakpoint-wide) {
    content: 'breakpoint-wide';
  }
}
