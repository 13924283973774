.header .menu {
  display: flex;
  padding: 0;
  margin: 0 0.5em;
  list-style: none;

  &__link {
    color: $color-white;
    padding: 0 0.5em;
    text-decoration: none;
    transition: 0.25s;

    &:hover {
      color: $color-black;
    }
  }

  &__item {

    &:last-child() {
      a {
        padding-right: 0;
      }
    }

    &:first-child() {
      a {
        padding-left: 0;
      }
    }
  }
}
