label {
  display: block;
  margin: 1em 0 0;
  width: 100%;
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
textarea {
  border: 1px solid tint($color-grey, 66%);
  line-height: 1.5;
  margin: 0.5em 0;
  padding: 0.25em 0.5em;
  width: 100%;

  @include breakpoint($breakpoint-narrow) {
    width: 75%;
  }
}

select {
  margin: 0.5em 0;
}

input[type="submit"] {
  border: 0;
  background-color: $color-primary;
  color: $color-white;
  font-family: $font-family;
  width: auto;
}

.form-radios {
  * {
    display: inline-block;
  }

  label {
    width: auto;
    padding: 0 0.25em;
  }
}

.webform-component-fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
