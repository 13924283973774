.paragraphs-item-cta {
  cursor: pointer;
  font-family: $font-headline;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin: 1em 0 - $page-gutter / 2 0 - $page-gutter;

  @include breakpoint($breakpoint-narrow) {
    margin: 2em (0 - $page-gutter * 4) -3em;
  }

  @include breakpoint($breakpoint-tablet-landscape) {
    margin: 2em (0 - $page-gutter * 5) -3em;
  }

  @include breakpoint($breakpoint-wide) {
    margin: 2em 0 - $page-gutter 0 - 3em;
  }

  .cta__link {
    color: $color-white;
    text-decoration: none;

    strong {
      color: $color-primary;
      font-weight: normal;
    }
  }

  .field-name-field-image {
    padding: 1em 0;
    background: linear-gradient(to top, $color-grey-dark 50%, transparent 50%);

    img {
      border-radius: 50%;
      border: 0.1em solid $color-white;
      display: inline-block;
      width: 20%;
    }
  }



  .field-name-field-text {
    background: $color-grey-dark;
    font-size: px2em(22);
    padding: 1em 0;

    @include breakpoint($breakpoint-wide) {
    border-radius: 0 0 0.5em 0.5em;
    }

    .highlight & {
      background: $color-blue-dark;
    }
  }

  .field-name-field-image + .field-name-field-text {
    padding-top: 0;
  }
}
