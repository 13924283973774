$component: 'subsection__info';


.#{$component} {
  background: $color-grey;
  display: flex;
  position: relative;
  margin: 0 auto;
  max-width: $page-max-width;
  z-index: 30;

  .highlight & {
    background: $color-blue;
  }

  @include breakpoint($breakpoint-wide) {
    background: none;

    .highlight & {
      background: none;
    }
  }

  h2,
  h3 {
    color: $color-primary;
    font-family: $font-family;
    font-size: px2em(20);
    font-weight: bold;

    .highlight & {
      color: $color-white;
    }
  }

  h3 {
    font-family: $font-courier;
  }

  ul {
    font-family: $font-courier;
    padding-left: 1em;

    p {
      font-size: px2em(15);
    }
  }

  table {
    font-family: $font-courier;
    font-size: px2em(15);

    td {
      width: 50%;
    }

    tr :last-child {
      text-align: right;
    }
  }

  &-inner {
    color: $color-white;
    padding: 1.5rem 1rem;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      margin: 0 auto;
      padding: 3rem 2rem;
      width: 75%;
    }

    @include breakpoint($breakpoint-wide) {
      background-color: $color-grey;
      margin: 40vh 0 40vh;
      width: 40%;
      border-radius: 0.5em;
      box-shadow: 0 0 1rem 0.05rem rgba($color-black, 0.25);

      .front .subsection:nth-child(1) & {
        margin: 85vh 0 85vh;
      }

      .subsection:nth-child(1) & {
        margin: 76vh 0 85vh;
      }



      .highlight & {
        background: $color-blue;
      }
    }
  }

  &-item {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .paragraphs-item-subsection.right & {
    justify-content: flex-start;
  }

  .paragraphs-item-subsection.left & {
    justify-content: flex-end;
  }

  .paragraphs-item-image {
    margin: 2em 0;
  }

  .paragraphs-item {

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.paragraphs-items-field-contents {
  //overflow: auto;
}
