$component: 'subsection';

.#{$component} {
  backface-visibility: hidden;
  overflow: hidden;
  position: relative;

  @include breakpoint($breakpoint-wide) {
    min-height: 100vh;
    padding: 0 $page-gutter;
  }

  &__teaser,
  &__teaser-clone {
    backface-visibility: hidden;
    left: 0;
    overflow: hidden;
    position: relative;
    top: 0;
    transform: translateZ(0);
    width: 100%;
    will-change: position, opacity;
    z-index: 10;

    @include breakpoint($breakpoint-narrow) {
      height: 100vw;
      padding: 0 $page-gutter;
    }

    @include breakpoint($breakpoint-tablet-landscape) {
      height: 100vh;
    }

    @include breakpoint($breakpoint-wide) {
      height: 100vh;
      opacity: 0;
      padding: 0 $page-gutter;
      pointer-events: none;
      position: fixed;
      visibility: hidden;
    }
  }

  &__teaser-clone {
    opacity: 1;
    position: absolute;
    z-index: 0;
    visibility: visible;
  }

  &:nth-child(1) {
    .#{$component}__teaser {
      opacity: 1;
    }
  }

  &--visible {

    .#{$component}__teaser,
    .#{$component}__teaser-clone {
      visibility: visible;
    }
  }

  &--fixed {

    .#{$component}__teaser {
      //opacity: 1;
      pointer-events: auto;

      z-index: 30;
    }
  }

  &--top {
    .#{$component}__teaser {
      position: absolute;
    }

    .#{$component}__teaser-clone {
      z-index: 20;
    }
  }

  &--bottom {
    .#{$component}__teaser {
      position: absolute;
      bottom: 0;
      top: auto;
      visibility: visible;
    }

  }


  &__teaser-background {
    background-color: $color-white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vw;
    width: 100%;

    @include breakpoint($breakpoint-narrow) {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .right .#{$component}__teaser-background {
    background-position: right center;

    @include breakpoint($breakpoint-wide) {
      background-position: center;
    }
  }

  .left .#{$component}__teaser-background {
    background-position: left center;

    @include breakpoint($breakpoint-wide) {
      background-position: center;
    }
  }

  .#{$component}--with-teaser .#{$component}__teaser-background,
  body:not(.front) & .#{$component}__teaser-background {
    background-position: center;

    &::after {
      background: linear-gradient(to top, rgba($color-black,0.6), rgba($color-black,0) 50%);
      content: '';
      position: absolute;
      height: 100vw;
      width: 100%;

      @include breakpoint($breakpoint-narrow) {
        background: linear-gradient(to top, rgba($color-black,0.6), rgba($color-black,0));
        bottom: 0;
        height: 50%;
        top: auto;
      }
    }
  }
}
