.section-user,
.section-users,
.section-403,
.section-404 {

  input {
    color: $color-black;
  }

  .tabs {
    list-style: none;
    padding: 0;
  }

  .main {
    color: $color-white;
    padding: 6em 1em 1em;

    @include breakpoint($breakpoint-narrow) {
      padding: 8em 1em 1em;
    }
  }

  .content {
    max-width: $page-max-width;
    margin: 0 auto;
  }
}
