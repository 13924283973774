@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=PT+Mono');

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome/fontawesome-webfont.eot?v=4.5.0');
  src: url('../fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), url('../fonts/fontawesome/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), url('../fonts/fontawesome/fontawesome-webfont.woff?v=4.5.0') format('woff'), url('../fonts/fontawesome/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), url('../fonts/fontawesome/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'Ludicrous';
	src: url('../fonts/ludicrous/Ludicrous-Regular.eot');
	src: url('../fonts/ludicrous/Ludicrous-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/ludicrous/Ludicrous-Regular.woff') format('woff'),
		url('../fonts/ludicrous/Ludicrous-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Blog Script';
  src: url('../fonts/1574062/b4087f22-4f10-4c1b-a0fd-c9b013c325f6.eot?#iefix');
  src: url('../fonts/1574062/b4087f22-4f10-4c1b-a0fd-c9b013c325f6.eot?#iefix') format('eot'), url('../fonts/1574062/1bb43302-d162-4308-b421-10400d76e530.woff2') format('woff2'), url('../fonts/1574062/5a777e9f-34dc-41d1-8058-8ebb1345ac32.woff') format('woff'), url('../fonts/1574062/b7bee550-e68a-4aac-950b-893f84a7b913.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Blog Script';
  src: url('../fonts/1574064/a8aa8725-eac1-4f53-9cbc-64a82a074c37.eot?#iefix');
  src: url('../fonts/1574064/a8aa8725-eac1-4f53-9cbc-64a82a074c37.eot?#iefix') format('eot'), url('../fonts/1574064/85c4764c-a16d-4e57-ac27-1a8a9f24a271.woff2') format('woff2'), url('../fonts/1574064/d4222e93-d151-404f-a614-a33697dac925.woff') format('woff'), url('../fonts/1574064/a45a4a38-7058-4688-81ff-aa00ad3e00e3.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
