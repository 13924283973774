.header {
  background-color: rgba($color-primary, 0.95);
  height: $header-height - 1em;
  left: 0;
  padding: 0 $page-gutter / 2;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;
  box-shadow: 0 0 1rem 0.05rem rgba($color-black, 0.25);

  @include breakpoint($breakpoint-narrow) {
    height: $header-height;
    padding: 0 $page-gutter;
  }

  @include breakpoint($breakpoint-wide) {
    padding: 0 $page-gutter;
    top: auto;

    .front & {
      position: absolute;
      bottom: 0;
    }

    .front &--fixed,
    &--fixed {
      position: fixed;
      top: 0;
    }
  }

  .block {
    align-items: center;
    display: none;

    @include breakpoint($breakpoint-wide) {
      display: flex;
      margin: 0 0.75em;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .block__title {
    color: $color-white;
    display: inline-block;
    font-size: px2em(16);
    margin: 0;
  }

  &__region {
    display: flex;
    align-items: center;
  }

  .logo {
    line-height: 0;
    margin-top: -0.5em;
    width: 40%;

    @include breakpoint($breakpoint-narrow) {
      margin-top: -1em;
      width: auto;
    }
  }

  &-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    max-width: $page-max-width;
    margin: 0 auto;
  }
}
