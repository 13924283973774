$component: 'section';

.#{$component} {
  background-color: $color-white;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @include breakpoint($breakpoint-wide) {
    min-height: 100vh;
  }
}
